<template>
  <base-page>
    <Filters model="processes" color="process" slot="left-column" />
    <router-view />
  </base-page>
</template>

<script>
import { Filters } from '../../components/filters'

export default {
  name: 'ProcessesPage',
  components: { Filters },

  meta () {
    return {
      titleTemplate: title => `${title} - Processing | AGORA`
    }
  },

  mounted () {
    // this.$store.commit('pages/setBreadcrumbs', [
    //   { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
    //   {}
    // ])
  }
}
</script>
